/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

/*PERSONAL COMPONENT*/
import Register from "../auth/Register";
import Login from "../auth/Login";
import Modal from "../common/Modal";

/*REDUX FUNCTION*/
import {
  getPaidFormList,
  getPublicFormList,
} from "../../actions/projectActions";
import { getFieldsPrivate } from "../../actions/formActions";

/*PICTURE ASSET*/
import icon_check from "../../img/icon_check.svg";

/*GENERAL*/
import dict from "../../validation/dict.json";

class ThankYou extends Component {
  state = { modalLogin: false, modalThanks: true };

  componentDidMount() {
    const link = this?.props?.match?.params?.link;

    const params = {
      geo_layer_link: link,
    };
    this.props.getFieldsPrivate(params);
  }

  toggleThanks = () => {
    this.setState({ modalThanks: !this.state.modalThanks });
  };

  toggleLogin = () => {
    this.setState({ modalLogin: !this.state.modalLogin });
  };

  convert_to_line_break = (long_text) => {
    const result = long_text.split("\n").map((line, index, array) =>
      index === array.length - 1 ? (
        this.translate_links(line)
      ) : (
        <React.Fragment key={index}>
          {this.translate_links(line)}
          <br />
        </React.Fragment>
      )
    );
    return result;
  };

  translate_links = (inputString) => {
    const linkRegex = /(https?:\/\/\S+)/g;

    // Mencari semua kecocokan dengan regex
    const matches = inputString.match(linkRegex);

    if (!matches) {
      // Jika tidak ada link, kembalikan string asli
      return inputString;
    }

    // Menerjemahkan link menjadi elemen <Link>
    const translatedString = inputString.split(linkRegex).map((part, index) => {
      if (matches.includes(part)) {
        return (
          <Link
            key={index}
            to={{ pathname: part }}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "underline",
              color: "blue",
            }}
          >
            {part}
          </Link>
        );
      } else {
        return part;
      }
    });

    return translatedString;
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const { modalLogin } = this.state;

    //global props
    const { domain } = this.props.auth;
    const { geo_layer_wo_geojson } = this.props.form;

    //content
    const project_public = geo_layer_wo_geojson?.geo_project?.isPublic;
    const project_link = geo_layer_wo_geojson?.geo_project?.link;
    const {
      isCustomFormMessage,
      customFormMessageHeader,
      customFormMessageDesc,
    } = geo_layer_wo_geojson;
    const { isRegistered, isAuthenticated } = this.props.auth;
    let header_link = "https://geo.mapid.io";
    if (domain === "cilicis.mapid.io") {
      header_link = "https://bbwsciliwungcisadane.mapid.io";
    } else if (domain === "brantas.mapid.io") {
      header_link = "https://bbwsbrantas.mapid.io";
    }
    let title = dict?.["Thank you for contributing"]?.[language];
    let sub_title =
      dict?.["Survey data has been successfully sent"]?.[language];
    if (isCustomFormMessage) {
      title = customFormMessageHeader;
      sub_title = customFormMessageDesc;
    }

    let itemContent = (
      <main>
        <main
          className="two_container_dashboard"
          style={{
            backgroundColor: "#fff",
            borderRadius: "0px 0px 30px 30px",
            padding: "25px",
            marginTop: "-30px",
            marginBottom: "30px",
          }}
        >
          <section style={{ marginTop: "auto", marginBottom: "auto" }}>
            <section
              style={{
                marginBottom: "70px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div className="text_big">{title}</div>
              <div className="text_medium">
                {this.convert_to_line_break(sub_title)}
              </div>
              <Link
                to={`/${geo_layer_wo_geojson.link}`}
                className="button_standard"
                style={{ marginRight: "5px", marginTop: "10px" }}
              >
                {dict?.["Fill again"]?.[language]}
              </Link>
            </section>

            <div className="text_inferior">
              {dict?.["Let's register for a MAPID account"]?.[language]}
            </div>
            <div className="text_inferior">
              {dict?.["By registering a MAPID account, you can:"]?.[language]}
              <ul>
                <li>{dict?.["Save the survey for offline use"]?.[language]}</li>
                <li>
                  {dict["Get"][language]}
                  <b>{` ${dict["incentives"][language]} `}</b>
                  {dict["from paid surveys"][language]}
                </li>
                <li>
                  {
                    dict["Actively monitor & assist active survey research"][
                      language
                    ]
                  }
                </li>
              </ul>
            </div>
            <button
              onClick={this.toggleLogin}
              className="button_very_small"
              id="blue"
              style={{ margin: "5px" }}
            >
              {dict["Register a MAPID account"][language]}
            </button>
            <Link
              to="/menu/offline"
              className="button_very_small"
              id="blue"
              style={{ margin: "5px" }}
            >
              {dict["Offline mode"][language]}
            </Link>
          </section>
          <section style={{ marginTop: "auto", marginBottom: "auto" }}>
            <img alt="FORM MAPID" src={icon_check} width="100%" />
          </section>
        </main>
      </main>
    );

    if (isAuthenticated) {
      itemContent = (
        <main
          style={{
            backgroundColor: "#fff",
            borderRadius: "0px 0px 30px 30px",
            padding: "25px",
            marginTop: "-30px",
            marginBottom: "30px",
          }}
        >
          <section
            style={{ marginTop: "auto", marginBottom: "auto", width: "100%" }}
          >
            <section
              style={{
                marginBottom: "70px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div className="text_big">{title}</div>
              <div className="text_medium break_word">
                {this.convert_to_line_break(sub_title)}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Link
                  to={`/${geo_layer_wo_geojson.link}`}
                  className="button_standard"
                  style={{
                    marginRight: "5px",
                    marginTop: "10px",
                    height: "fit-content",
                  }}
                >
                  {dict["Fill again"][language]}
                </Link>
                <section
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100px",
                    height: "100px",
                  }}
                >
                  <img alt="FORM MAPID" src={icon_check} width="100%" />
                </section>
              </div>
            </section>
            <Link
              to="/menu/offline"
              style={{ marginRight: "5px", marginBottom: "5px" }}
              className="button_standard"
            >
              {dict["Offline mode"][language]}
            </Link>
            <a
              href="https://geo.mapid.io"
              target="_blank"
              rel="noopener noreferrer"
              className="button_standard"
              style={{ marginRight: "5px", marginBottom: "5px" }}
            >
              {dict["Create a similar form"][language]}
            </a>
            <Link
              to="/"
              style={{ marginRight: "5px", marginBottom: "5px" }}
              className="button_standard"
            >
              Home
            </Link>
          </section>
        </main>
      );
    } else if (["cilicis.mapid.io", "brantas.mapid.io"].includes(domain)) {
      itemContent = (
        <main>
          <main>
            <main
              className="two_container_dashboard"
              style={{
                backgroundColor: "#fff",
                borderRadius: "0px 0px 30px 30px",
                padding: "25px",
                marginTop: "-30px",
                marginBottom: "30px",
              }}
            >
              <section style={{ marginTop: "auto", marginBottom: "auto" }}>
                <section style={{ marginBottom: "70px" }}>
                  <div className="text_big">
                    {dict["Thank you for contributing"][language]}
                  </div>
                  <div className="text_medium break_word">
                    {dict["Survey data has been successfully sent"][language]}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link
                      to={`/${geo_layer_wo_geojson.link}`}
                      className="button_standard"
                      style={{ marginRight: "5px", marginTop: "10px" }}
                    >
                      {dict["Fill again"][language]}
                    </Link>
                    <section
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        width: "100px",
                        height: "100px",
                      }}
                    >
                      <img alt="SWC" src={icon_check} width="100%" />
                    </section>
                  </div>
                </section>
                <Link
                  to="/menu/offline"
                  style={{ marginRight: "5px", marginBottom: "5px" }}
                  className="button_standard"
                >
                  {dict["Offline mode"][language]}
                </Link>
              </section>
            </main>
          </main>
        </main>
      );
    }
    const modalLoginContent = modalLogin && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalLogin}
        onClose={this.toggleLogin}
      >
        <div className="box-body">
          {!isRegistered ? <Register /> : <Login />}
        </div>
      </Modal>
    );
    return (
      <div className="main_container">
        {itemContent}
        <section style={{ textAlign: "center" }}>
          {/* <p className="text_bold">{geo_layer_wo_geojson?.name}</p> */}
          {/* <p className="text_inferior">{geo_layer_wo_geojson?.description}</p> */}
          {geo_layer_wo_geojson?.picture_url && (
            <div
              style={{
                backgroundImage: `url(${geo_layer_wo_geojson?.picture_url})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundColor: "#dedede",
                width: "100%",
                paddingBottom: "25%",
                borderRadius: "10px",
                marginTop: "30px",
                marginBottom: "10px",
              }}
            />
          )}

          {project_public && (
            <a
              href={`${header_link}/view/${project_link}?layer_id=${geo_layer_wo_geojson.link}`}
              target="_blank"
              rel="noopener noreferrer"
              className="button_standard"
            >
              {dict["View data on a map"][language]}
            </a>
          )}
        </section>
        {modalLoginContent}
        <Helmet>
          <title>{dict["Success"][language]}</title>
          <meta name="description" content={`Success`} />
        </Helmet>
        <style>{`
        .nav_bottom{ display:none;}
     `}</style>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  project: state.project,
  form: state.form,
});

export default connect(mapStateToProps, {
  getPaidFormList,
  getPublicFormList,
  getFieldsPrivate,
})(ThankYou);
