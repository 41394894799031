//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
//Personal Component
import Modal from "../common/Modal";
//Redux function
import {
  setLayerWOgeojson,
  clearFormOffline,
  deleteFormOffline,
} from "../../actions/formActions";
//Picture Asset
//General Function
import history from "../../actions/history";

//General Function
import dict from "../../validation/dict.json";
import CLONE_BUTTON from "../offline_form/CLONE_BUTTON";

class OfflineList extends Component {
  state = {
    modal_clear: false,
    modal_delete: false,
    layer: {},
  };
  toggleClear = () => {
    this.setState({ modal_clear: !this.state.modal_clear });
  };
  toggleDelete = (layer) => {
    this.setState({ modal_delete: !this.state.modal_delete });
    if (layer) {
      this.setState({ layer });
    }
  };
  onClear = () => {
    this.props.clearFormOffline();
    history.push("/menu/offline");
    this.setState({ modal_clear: false });
  };
  onDelete = () => {
    const { layer } = this.state;
    const { id_offline } = layer;
    this.props.deleteFormOffline(id_offline);
    history.push("/menu/offline");
    this.setState({ modal_delete: false });
  };
  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    const { modal_clear, modal_delete, layer } = this.state;
    let fields = [];
    let first_content;
    if (layer._id) {
      fields = layer.fields;
      if (fields.length > 0) {
        const first_field = fields[0];
        const { name, value } = first_field;
        let text_content = value;

        if (typeof value === "object") {
          const { filename, base_64 } = value;
          text_content = base_64 ? filename?.split("_%")?.[1] : "";
        }

        first_content = (
          <div className="text_small">
            {name}: <b>{text_content}</b>
          </div>
        );
      }
    }
    const form_list_offline = localStorage.form_list_offline
      ? JSON.parse(localStorage.form_list_offline)
      : [];
    let itemContent = (
      <div className="container_light">
        {dict["There is no offline survey yet"][language]}
      </div>
    );
    if (form_list_offline.length > 0) {
      itemContent = (
        <div style={{ marginTop: "30px" }}>
          {form_list_offline.map((layer_e, idx) => {
            const { name, fields, id_offline } = layer_e?.geo_layer_wo_geojson;
            let first_content;
            if (fields.length > 0) {
              const first_field = fields[0];
              const { name, value } = first_field;
              let text_content = value;
              if (typeof value === "object") {
                const { filename, base_64 } = value;
                text_content = base_64 ? filename?.split("_%")?.[1] : "";
              }

              first_content = (
                <div className="text_small">
                  {name}: <b>{text_content}</b>
                </div>
              );
            }
            return (
              <main key={idx}>
                <main
                  style={{
                    backgroundColor: "#fff",
                    marginBottom: "10px",
                    cursor: "pointer",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <section
                    className="text_bold"
                    style={{ maxHeight: "40px", overflowY: "hidden" }}
                  >
                    {name}
                  </section>
                  {first_content}
                  <section
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <section>
                      <Link
                        to={`/offline/${id_offline}`}
                        className="button_standard"
                        style={{ marginRight: "5px" }}
                      >
                        {dict["Edit"][language]}
                      </Link>
                      <button
                        onClick={this.toggleDelete.bind(
                          this,
                          layer_e?.geo_layer_wo_geojson
                        )}
                        className="button_standard"
                        id="red"
                      >
                        {dict["Delete"][language]}
                      </button>
                    </section>
                    <section>
                      <CLONE_BUTTON id_offline={id_offline} />
                    </section>
                  </section>
                </main>
              </main>
            );
          })}
        </div>
      );
    }
    const modal_clear_content = modal_clear && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_clear}
        onClose={this.toggleClear}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          <div className="text_medium" style={{ marginBottom: "50px" }}>
            {
              dict[
                "Are you sure you want to delete all the offline surveys stored on this device?"
              ][language]
            }
            <br />
            {dict["This process cannot be undone"][language]}
          </div>
          <button
            className="button_standard"
            onClick={this.onClear}
            style={{ marginBottom: "10px" }}
            id="red"
          >
            {dict["Delete all"][language]}
          </button>
        </div>
      </Modal>
    );
    const modal_delete_content = modal_delete && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_delete}
        onClose={this.toggleDelete}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          <div className="text_medium" style={{ marginBottom: "50px" }}>
            {
              dict["Are you sure you want to delete this offline survey?"][
                language
              ]
            }
            <br />
            {dict["This process cannot be undone"][language]}
          </div>
          <div className="text_bold">{layer.name}</div>
          <div>{first_content}</div>
          <button onClick={this.onDelete} className="button_standard" id="red">
            {dict["Delete"][language]}
          </button>
        </div>
      </Modal>
    );
    return (
      <main className="main_container">
        <section className="text_bold">
          {dict["Offline Form"][language]}
        </section>
        <section className="text_medium">
          {dict["Click the banner to fill out the form"][language]}
        </section>
        {form_list_offline.length > 0 && (
          <button
            className="button_very_small"
            onClick={this.toggleClear}
            style={{ marginBottom: "10px" }}
            id="red"
          >
            {dict["Delete all"][language]}
          </button>
        )}
        {itemContent}
        {modal_clear_content}
        {modal_delete_content}
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  project: state.project,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  setLayerWOgeojson,
  clearFormOffline,
  deleteFormOffline,
})(OfflineList);
